import React from "react";

import style from "./style.module.css";
import CTA from "../../components/cta";

const JobDepartments = ({ departments }) => {
  if (!departments) {
    // If the API is slow or down, offer a straight link to the listings hosted on Greenhouse
    return (<CTA ctaLink={"https://boards.greenhouse.io/rocksteadymusicschool/"} ctaText="See Job Openings" siteSection="generic" />)
  }
  return (
    <ul>
      {
        departments.map((department) => {
          const jobs = department.jobs;

          // Greenhouse returns all departments even if no jobs open
          if (jobs.length === 0) {
            return null;
          }
          
          return(
            <li key={`dep-${department.id}`} className={style.listItem}>
              <a href={`#department-${department.id}`}>{department.name}</a>
            </li>
          )
        })
      }
    </ul>
  );
};

export default JobDepartments;
