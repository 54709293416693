import React, { useEffect, useState } from "react";
import { Container, Grid, Icon, List, Segment } from "semantic-ui-react";
import scrollTo from 'gatsby-plugin-smoothscroll';
import LayoutInner from "../../../components/layout/layout-inner";
import SEO from "../../../components/seo/seo";
import { SITE_URL } from "../../../constants";
import SecondaryNavBar from "../../../components/secondary-nav-bar/secondary-nav-bar";
import BodyContent from "../../../components/body-content/body-content";
import CTA from "../../../components/cta/cta";
import CookieUtils from '../../../utils/cookie-utils';
import JobListing from "../../../components/job-listing/job-listing";
import JobDepartments from "../../../components/job-departments/job-departments";
import mark from "../../../images/mark-portrait.png";
import QuoteSegment from "../../../components/quote-segment/quote-segment";
import wellbeingAwardWinners from "../../../images/wellbeing-award-winners.png";
import trinityCollege from "../../../images/trinity-college.png";
import mia from "../../../images/music-industries-association.png";
import ism from "../../../images/ism-corporate-member.png";
import SiteFooter from "../../../components/site-footer/site-footer"
import { navigate } from "gatsby"
import hideWidget from "../hideWidget";

import "../index.css";

// IMPORTANT:
// This page was the 'main' careers page for the website, but there is now another
// 'main' page customised to highlight a current band leader recruitment campaign '../careers.js'.
// As such, the canonical tag here points to the new 'main' careers page.

const Page = () => {
  const [urlParams, setUrlParams] = useState('');

  
  useEffect(() => {
    setUrlParams(CookieUtils.analyticsConsentGiven() ? '' : '?dnt=1');
  }, []);

  // Client-side Runtime Data Fetching
  const [departments, setdepartments] = useState([])
  useEffect(() => {
    // get data from Green House api
    fetch("https://boards-api.greenhouse.io/v1/boards/rocksteadymusicschool/departments")
      .then(response => response.json()) // parse JSON from request
      .then((resultData) => {
        setdepartments(resultData.departments.reverse())
      }) 
  }, [])

  // Pass along Greenhouse URL params for marketing campaigns
  // See tracking info on https://app2.greenhouse.io/jobboard/integration/documentation/api_board_hosted_apps
  const [source, setSource] = useState("rocksteady_www_no_referrer")
  useEffect(() => {
    // Get GH Source code from URL params
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const ghSrc = urlParams.get('gh_src');
    if(ghSrc) {
      setSource(ghSrc)
    }
  }, [])

  const bandLeaderBenefits = [
    'Full time salary',
    '12 weeks paid holiday',
    'Fuel allowance',
    'Full training and ongoing support',
    '24/7 online GP service',
    'Retailer discounts'
  ];
 
  const officeRoleBenefits = [
    '25 days paid holiday plus bank holidays',
    'Ongoing support and training opportunities',
    'Work from our beautiful office in the Hampshire countryside',
    'Complimentary snacks and fruit',
    '24/7 online GP service',
    'Retailer discounts'
  ];

  const quote = [
    'We cannot begin to quantify the impact Rocksteady has on our whole school community.',
    'These lessons aid not only music development but also social communication and interaction. The whole school community looks forward to their termly concerts when we come together and celebrate the achievements of so many children.'
  ];
  
  const quoter = "Alison Fitch, Co-Head, Boxgrove Primary School, Guildford";
  
  const wellBeingImageCaption = "Winner - 'Wellbeing Resource of the Year' - Education Today Awards 2022/2023";

  const renderBenefitsList = (benefitList) => benefitList.map((benefit) => (
    <List.Item key={benefit}>
      <Icon name='check' className="blue-suede-shoes" />
      <List.Content>{benefit}</List.Content>
    </List.Item>
  ));
 
  return (
    <LayoutInner>
      <SEO
        title="Careers - Music Jobs &amp; Office Roles"
        keywords={[`rocksteady`]}
        canonical={`${SITE_URL}/careers/`}
        description="Are you looking for a music job or an office role with purpose? At Rocksteady, we empower children through music education. Explore our music vacancies."
      />

      <Container>
        <SecondaryNavBar
          title="Careers"
          titleActive={true}
          titlePath="/careers/"
          product="generic"
          links={[]}
          cta="View Available Jobs"
          ctaLink="#view-available-jobs"
        />

        {hideWidget()}

        <h1>
          <img
            src="/images/careers/work-with-rocksteady-banner.png"
            alt="Work with Rocksteady Music School"
            className="ui fluid image"
          />
        </h1>

        <BodyContent>
          <h2>Looking for your dream job in music? This is it.</h2>
          <p>Our mission is to empower as many children as possible and transform their lives through progressive and inclusive music education. Therefore, we also make it our mission to recruit the very best people to pursue their dream music career here.</p>
        </BodyContent>

        <BodyContent>
          <Grid stackable>
          <Grid.Column width={8}>
              <Segment basic className="benefits-segment" style={{background: "#D2F0FB"}}>
                <h3>Office Role Benefits</h3>
                <List relaxed>{renderBenefitsList(officeRoleBenefits)}</List>
              </Segment>
              <div className="benefits-CTA-div">
                <CTA
                  ctaText="View All Roles"
                  onClick={() => scrollTo('#view-available-jobs')}
                  siteSection="generic"
                  className="benefits-CTA"
                />
              </div>
            </Grid.Column>
            <Grid.Column width={8}>
              <Segment basic className="benefits-segment" style={{background: "#D2F0FB"}}>
                <h3>Music Teacher Benefits</h3>
                <List relaxed>{renderBenefitsList(bandLeaderBenefits)}</List>
              </Segment>
              <div className="benefits-CTA-div">
                <CTA
                  ctaText="Visit Teaching Jobs Page"
                  onClick={() => navigate("/careers/")}
                  siteSection="generic"
                  className="benefits-CTA"
                />
              </div>
            </Grid.Column>
          </Grid>
        </BodyContent>

        <BodyContent>
    
        </BodyContent>

        <BodyContent>
          <p id="view-available-jobs">Rocksteady Music School is the world's leading rock and pop music teaching service for primary schools, teaching band lessons to tens of thousands of pupils every week, helping to inspire the next generation to discover their love of music.</p>
          <p>As a company that is proud to inspire children across the UK every week, your dream music job might be just one application away. Whether you're looking for a full-time music job, you're currently a music teacher looking for jobs with purpose, a musician looking for work, or someone looking for their next career challenge in a supportive environment, we would love to hear from you.</p>
        </BodyContent>

        <BodyContent>
        <Grid stackable columns={2} style={{ background: "#f5f5f4"}} id="jobs-grid">
          <Grid.Column id="jobs-column-left">
            <h2 className="job-header">Departments Currently Hiring</h2>
            <JobDepartments departments={departments} />
          </Grid.Column>
          <Grid.Column id="jobs-column-right">
            <h2 className="job-header">Current Job Openings</h2>
            <JobListing departments={departments} source={source} />
          </Grid.Column>
        </Grid>
        </BodyContent>

        {/* Video has been taken down temporarily - reinstate when replacement is ready */}
        {/*<BodyContent>
          <div className="videoWrapper wide">
            <iframe
              className="careers-video"
              src={`https://player.vimeo.com/video/[id goes here]${urlParams}`}
              width="640"
              height="480"
              frameBorder="0"
              allow="autoplay; fullscreen"
              allowFullScreen
              title="Rocksteady Music School Careers"
            ></iframe>
          </div>
        </BodyContent>*/}

        <BodyContent>
          <Grid verticalAlign="middle" stretched>
            <Grid.Column only="computer" width={2} />
            <Grid.Column mobile={10} tablet={8} computer={6}>
              <img width="75%" src={mark} alt="Mark Robinson" />
            </Grid.Column>
            <Grid.Column mobile={16} tablet={8} computer={8}>
              <h2>Inspiring the next generation</h2>
              <p>Rocksteady was founded by Mark Robinson, a musician, music teacher and author. He saw first-hand how children progressed faster when they were playing popular rock and pop music in a band.</p>
              <p>Since then Rocksteady have grown to become the UK's leading rock and pop music teaching service for primary schools and one of the biggest employers of musicians in the UK. Teachers deliver accessible, progressive, band lessons to tens of thousands of pupils every week, helping to inspire the next generation of musicians.</p>
              <p>Our mission is to empower every child through music. In addition to teaching in schools, each year The Rocksteady Foundation organises hundreds of free music sessions for children and young people in charities and support groups across the UK. We enable some of the most vulnerable in society to enjoy the wellbeing, fun and educational benefits of playing music in a band.</p>
            </Grid.Column>
          </Grid>
        </BodyContent>

          </Container>
        
        <div className="section background-yellow mt-25">
          <QuoteSegment
            quote={[
              'Learning to play an instrument became less daunting to many children; it was about teamwork, having fun, expressing themselves in a safe environment and discovering a genuine love of learning music.'
            ]}
            quoter="Mark Robinson - Founder and Chairman, Rocksteady Music School"
          />
        </div>
        
        <div className="section mt-25">
          <Grid centered stackable columns={2}>
            <Grid.Row only="tablet computer">
            <Grid.Column computer={6} >
              <div>
                <img width="100%" src={wellbeingAwardWinners} alt="Rocksteady staff celebrating award win" />
              </div>
              <p className="blue-text">{wellBeingImageCaption}</p>
            </Grid.Column>
            <Grid.Column computer={8}>
              <QuoteSegment
                quote={quote}
                quoter={quoter}
              />
            </Grid.Column>
            </Grid.Row>
            <Grid.Row only="mobile">
            <Grid.Column>
              <div className="offset-gutter">
                <img width="100%" src={wellbeingAwardWinners} alt="Rocksteady staff celebrating award win" />
              <p className="blue-text mt-15">{wellBeingImageCaption}</p>
              </div>
            </Grid.Column>
            <Grid.Column>
            <div className="offset-gutter">
              <QuoteSegment
                quote={quote}
                quoter={quoter}
              />
            </div>
            </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>

        <div className="section background-sky-blue mt-25">
          <div className="partners-awards-badges">
            <img src={trinityCollege} alt="Trinity College logo" />
            <img src={mia} alt="MIA logo" />
            <img src={ism} alt="ISM logo" />
          </div>
        </div>

      <SiteFooter />
      <div id="sticky-jobs-button">
        <CTA
          ctaText="View All Roles"
          onClick={() => scrollTo('#view-available-jobs')}
          siteSection="careers"
        />
        <CTA
          ctaText="Visit Teaching Jobs Page"
          onClick={() => navigate("/careers/")}
          siteSection="careers"
          />
      </div>

    </LayoutInner>
  );
}

export default Page;
